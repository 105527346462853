<template>
  <div class="content-box">
    <div class="content-header">
      <div>
        <el-select
          v-model="diagnosesTypeId"
          class="input"
          placeholder="请选择诊断类型"
          clearable
        >
          <el-option
            v-for="item in diagnosesTypeList"
            :key="item.id"
            :label="item.dictValue"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          :value="storeName"
          @input="changeValue($event, 'storeName')"
          maxlength="50"
          class="input"
          clearable
          placeholder="输入关联门店"
        ></el-input>
        <el-button type="plain" class="cotent-btn" @click="handleSearch"
          >搜索</el-button
        >
        <el-button
          type="plain"
          class="cotent-btn-big"
          icon="el-icon-plus"
          @click="handleEdit"
          >新增诊断次数</el-button
        >
      </div>
      <div class="header-tips">
        <label>总剩余次数：</label>
        <el-popover
          placement="bottom"
          width="260"
          trigger="click">
            <div class="header-tips-wrap">
              <label>总剩余次数 {{ totalLeft }}</label>
              <div class="header-tips-split"></div>
              <div class="header-tips-box">
                <div>舌诊 {{ leftTongueTimes }}</div>
                <div>体质辨识 {{ leftPhysiqueTimes }}</div>
                <div>脉诊 {{ leftPulseTimes }}</div>
                <div>肺功能 {{ leftLungTimes }}</div>
              </div>
              <p>注意：本次计算不包含无限次在内</p>
            </div>
          <span slot="reference">{{ totalLeft }}</span>
        </el-popover>
        <label>总分配次数：</label>
        <el-popover
          placement="bottom"
          width="260"
          trigger="click">
            <div class="header-tips-wrap">
              <label>总分配次数 {{ totalDispatch }}</label>
              <div class="header-tips-split"></div>
              <div class="header-tips-box">
                <div>舌诊 {{ dispatchTongueTimes }}</div>
                <div>体质辨识 {{ dispatchPhysiqueTimes }}</div>
                <div>脉诊 {{ dispatchPulseTimes }}</div>
                <div>肺功能 {{ dispatchLungTimes }}</div>
              </div>
              <p>注意：本次计算不包含无限次在内</p>
            </div>
          <span slot="reference">{{ totalDispatch }}</span>
        </el-popover>
        <label>总检测次数：</label>
        <el-popover
          placement="bottom-end"
          width="260"
          trigger="click">
            <div class="header-tips-wrap-small">
              <label>总检测次数 {{ totalUsed }}</label>
              <div class="header-tips-split"></div>
              <div class="header-tips-box">
                <div>舌诊 {{ usedTongueTimes }}</div>
                <div>体质辨识 {{ usedPhysiqueTimes }}</div>
                <div>脉诊 {{ usedPulseTimes }}</div>
                <div>肺功能 {{ usedLungTimes }}</div>
              </div>
            </div>
          <span slot="reference">{{ totalUsed }}</span>
        </el-popover>
      </div>
    </div>
    <div class="table-list">
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="700"
        :header-cell-style="headerCellStyle"
        border
        v-loading="loading"
        :cell-style="cellStyle"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>

        <el-table-column prop="storeName" label="关联门店"> </el-table-column>

        <el-table-column prop="ownerName" label="诊断设备" align="center">
          <template slot-scope="scope">
            <div class="store-device">
              {{scope.row.diagnosesTypeId|diagnosesTypeText}}<span>余次{{ scope.row.tongueRemainingNumber <= -1 ? '无限' : (scope.row.tongueRemainingNumber || 0) }}次</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lastTime" label="最近添加时间">
        </el-table-column>
        <el-table-column prop="diagnosesList" label="添加详情">
          <template slot-scope="scope">
            <el-button
              class="diagnosesList"
              type="text"
              @click="handleDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="activationTime" label="激活时间">
        </el-table-column>
        <el-table-column prop="expireTime" label="到期时间">
          <template slot-scope="scope">
            {{ expireTimeDesc(scope.row.expireTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >增加次数</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        style="margin-top: 20px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="itemTotal"
      ></el-pagination>

      <el-dialog
        title="新增门店诊断次数"
        :visible.sync="dialogVisible"
        width="460px"
        :before-close="dialogClose"
        v-if="dialogVisible"
      >
        <div class="edit-box">
          <el-form
            label-position="left"
            label-width="100px"
            :model="formData"
            ref="validateForm"
            :rules="rules"
          >
            <el-form-item label="关联门店:" prop="storeId">
              <el-select
                clearable
                v-model="formData.storeId"
                class="dialogInput"
                remote
                :remote-method="getAllStoreList"
                @focus="getAllStoreList"
                filterable
                placeholder="请选择关联门店"
                :disabled="disableStore"
              >
                <el-option
                  v-for="item in allStoreList"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="诊断类型:" prop="diagnosesTypeId">
              <el-select
                clearable
                v-model="formData.diagnosesTypeId"
                class="dialogInput"
                remote
                :remote-method="getTypeList"
                @focus="getTypeList"
                placeholder="请选择诊断类型"
                :disabled="disableStore"
              >
                <el-option
                  v-for="item in diagnosesTypeList"
                  :key="item.id"
                  :label="item.dictValue"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="添加次数:" prop="diagnosesNumber">
              <el-select
                class="dialogInput"
                v-model="formData.diagnosesNumber"
                clearable
                placeholder="请添加诊断次数"
              >
                <el-option
                  v-for="item in diagnosesNumbers"
                  :key="item.diagnosesNumber"
                  :label="item.label"
                  :value="item.diagnosesNumber"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="到期时间:" prop="expireTime">
              <el-select @change="limitStatusChange" style="width: 96px;margin-right: 4px" v-model="formData.limitStatus" placeholder="请选择">
                <el-option
                  v-for="item in getLimitStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker
                style="width: 200px"
                v-model="formData.expireTime"
                :picker-options="pickerOptions"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                class="dialogInput"
                :disabled="formData.limitStatus == 1"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input
                type="textarea"
                v-model="formData.remark"
                class="dialogInput"
                maxlength="100"
                placeholder="请输入备注内容"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button type="primary" @click="dialogConfirm">提交</el-button>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        title="查询添加详情"
        :visible.sync="detailVisible"
        width="800px"
        :before-close="detailClose"
      >
        <div>
          <div class="detail-top">
            <span>
              门店名称：{{ deatilData.storeName }}
            </span>
            <el-select class="input" @change="useStatusChange" v-model="useStatus" clearable>
              <el-option
                  v-for="item in useStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
              </el-option>
            </el-select>
          </div>
          <div class="detail-scroll">
            <el-table
              :data="diagnosesList"
              style="width: 100%"
              max-height="280"
            >
              <el-table-column prop="createTime" label="添加时间">
              </el-table-column>
              <el-table-column prop="diagnosesNumber" label="添加次数">
                <template slot-scope="scope">
                  {{ getTongueTimes(scope.row.diagnosesNumber) }}次
                </template>
              </el-table-column>
              <el-table-column prop="expireTime" label="到期时间">
                <template slot-scope="scope">
                  {{
                    scope.row.limitStatus
                      ? "无限期"
                      : scope.row.expireTime
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="expireStatus" label="状态">
                <template #default="{row}">
                  {{row.expireStatusDesc}}
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注"> </el-table-column>
            </el-table>
          </div>
          <div class="detail-tip">
            <div>注 : 以先到为准</div>
            <div>1）次数消耗完毕，暂停使用</div>
            <div>2）已过有效期，次数重置清零且暂停使用</div>
            <div>3）对应的次数和到期时间操作停用后，暂停使用</div>
            <div>4）有多条记录时，按添加时间顺序开始消耗次数</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";

import { storeDiagnoses, getDiagnosesTypeList, getAllDiagnosesData, save } from "@/api/tongue";
import { allStoreList } from "@/api/store";
import { DiagnosesTypeEnum } from "@/enum/common.enum";

// 工具
import deepCopy from "@/utils/deepCopy";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

const initFormData = {
  diagnosesNumber: "",
  diagnosesTypeId: "",
  diagnosesTypeName: "",
  storeId: "",
  remark: "",
  expireTime: "",
};

@Component
export default class StoreList extends Mixins(loading, tablePagination) {
  storeName = "";
  diagnosesTypeId="";
  tableData = [];
  itemTotal = 0;
  dialogVisible = false;

  useStatus = "使用中";
  useStatusOptions = [
    { label: "全部", value: "" },
    { label: "使用中", value: "1" },
    { label: "已过期", value: "3" },
    { label: "已用完", value: "4" },
    { label: "已停用", value: "5" },
  ];
  pickerOptions = {
    disabledDate: (date) => {
      // 获取今天的日期
      const today = dayjs(new Date()).startOf("d");
      // 将选中的日期转换为Date对象
      const selectedDate = dayjs(new Date(date)).startOf("d");
      // 比较选中的日期是否在今天之前
      return selectedDate.isSameOrBefore(today);
    }
  }
  totalLeft = 0;
  totalDispatch = 0;
  totalUsed = 0;
  leftTongueTimes = 0;
  leftPhysiqueTimes = 0;
  leftPulseTimes = 0;
  leftLungTimes = 0;
  dispatchTongueTimes = 0;
  dispatchPhysiqueTimes = 0;
  dispatchPulseTimes = 0;
  dispatchLungTimes = 0;
  usedTongueTimes = 0;
  usedPhysiqueTimes = 0;
  usedPulseTimes = 0;
  usedLungTimes = 0;
  formData = {
    diagnosesNumber: "",
    diagnosesTypeId: "",
    diagnosesTypeName: "",
    storeId: "",
    remark: "",
    limitStatus: 1,
    expireTime: "",
  };
  allStoreList = [];
  diagnosesTypeList = [];
  diagnosesNumbers = [
    { diagnosesNumber: 50, label: 50 },
    { diagnosesNumber: 100, label: 100 },
    { diagnosesNumber: 300, label: 300 },
    { diagnosesNumber: 500, label: 500 },
    { diagnosesNumber: 1000, label: 1000 },
    { diagnosesNumber: -1, label: "无限次" },
  ];
  limitStatusOptions = [{ label: "无限期", value: 1 },{ label: "自定义", value: 0 }]

  detailVisible = false;
  deatilData = {};
  diagnosesList = [];
  disableStore = false;

  rules = {
    storeId: [{ required: true, message: "请选择关联门店", trigger: "change" }],
    diagnosesTypeId: [
      { required: true, message: "请选择诊断类型", trigger: "change" },
    ],
    diagnosesNumber: [
      { required: true, message: "请选择诊断次数", trigger: "change" },
    ],
    expireTime: [ { required: true, validator: this.validatorExpireTime } ]
  };

  get getLimitStatusOptions() {
      return this.formData.diagnosesTypeId == "10003" ? [this.limitStatusOptions[1]] : this.limitStatusOptions;
  }

  mounted() {
    this.getData();
    this.getAllStoreList();
    this.getTypeList();
    this.getAllDiagnoses();
  }

  validatorExpireTime(rule, value, callback) {
    if (this.formData.limitStatus == 1) { // 无限制，不用输入时间
      return callback();
    }
    if (this.formData.limitStatus == 0) { // 有限制，需要时间
      if (!this.formData.expireTime) {
        return callback(new Error("请选择到期时间"))
      }
    }
    return callback();
  }

  limitStatusChange(value) {
    if (value == 1) {
      this.formData.expireTime = "";
    }
    this.formData = cloneDeep(this.formData)
  }

  expireTimeDesc(expireTime) {
    if (expireTime.includes("9999")) return "无限期";
    return expireTime;
  }

  getData() {
    this.showLoading();
    storeDiagnoses({
      storeName: this.storeName || null,
      diagnosesTypeId: this.diagnosesTypeId || null,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    })
      .then((res) => {
        this.hideLoading();
        this.tableData = res.data.list;
        this.itemTotal = res.data.total;
        this.tableData?.forEach((el) => {
          el.diagnosesList?.forEach((row) => {
            let expireStatusDesc = "";
            if (row.expireStatus == 1) {
              expireStatusDesc = "已过期"
            } else if (row.expireStatus == 2) {
              expireStatusDesc = "已停用"
            } else {
              if (row.expireStatus == 0 && row.useNumber == row.diagnosesNumber) {
                expireStatusDesc = "已用完"
              }
              if (row.expireStatus == 0 && row.useNumber == 0 && row.diagnosesNumber != -1) {
                expireStatusDesc = "未使用"
              }
              if (row.expireStatus == 0 && row.useNumber && row.useNumber < row.diagnosesNumber || row.diagnosesNumber <= -1) {
                expireStatusDesc = "使用中"
              }
            }
            row.expireStatusDesc = expireStatusDesc;
          })
        })
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  getAllStoreList(str) {
    if (typeof str == "object") {
      str = "";
    }
    allStoreList({
      storeName: str,
    })
      .then((res) => {
        this.allStoreList = res.data;
      })
      .catch((err) => {});
  }

  getTypeList() {
    getDiagnosesTypeList()
      .then((res) => {
        this.diagnosesTypeList = res.data;
      })
      .catch((err) => {});
  }

  getAllDiagnoses() {
    const params = { isAllStore: 1 }
    getAllDiagnosesData(params)
      .then((res) => {
        const { totalLeft, totalDispatch, totalUsed, leftTongueTimes, leftPhysiqueTimes, leftPulseTimes, leftLungTimes,
          dispatchTongueTimes, dispatchPhysiqueTimes, dispatchPulseTimes, dispatchLungTimes,
          usedTongueTimes, usedPhysiqueTimes, usedPulseTimes, usedLungTimes } = res.data;

          this.totalLeft = totalLeft;
          this.totalDispatch = totalDispatch;
          this.totalUsed = totalUsed;
          this.leftTongueTimes = leftTongueTimes;
          this.leftPhysiqueTimes = leftPhysiqueTimes;
          this.leftPulseTimes = leftPulseTimes;
          this.leftLungTimes = leftLungTimes;
          this.dispatchTongueTimes = dispatchTongueTimes;
          this.dispatchPhysiqueTimes = dispatchPhysiqueTimes;
          this.dispatchPulseTimes = dispatchPulseTimes;
          this.dispatchLungTimes = dispatchLungTimes;
          this.usedTongueTimes = usedTongueTimes;
          this.usedPhysiqueTimes = usedPhysiqueTimes;
          this.usedPulseTimes = usedPulseTimes;
          this.usedLungTimes = usedLungTimes;
      })
      .catch((err) => {});
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  // 头部输入
  changeValue(val, type) {
    this[type] = val;
  }

  getName(target, id, keyId, keyName) {
    let name = "";
    target.forEach((item) => {
      if (item[keyId] == id) {
        name = item[keyName];
      }
    });
    return name;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }

  handleEdit(row = {}) {
    let { storeId, diagnosesTypeId, limitStatus = 1 } = row;
    if (storeId) this.disableStore = true; // 表格中的数据 新增 要带出门店，
    this.dialogVisible = true;
    this.formData = {
      storeId,
      diagnosesTypeId,
      limitStatus: 1
    };
  }

  handleDetail(row) {
    this.deatilData = deepCopy(row);
    this.detailVisible = true;
    this.useStatus = "使用中";
    this.useStatusChange("使用中")
  }

  detailClose() {
    this.detailVisible = false;
    this.useStatus = "1";
  }

  dialogClose() {
    this.dialogVisible = false;
    this.$refs["validateForm"].resetFields();
    this.disableStore = false;
    // 调用了resetFields 是不需要自己再手动重置
    this.formData = deepCopy(initFormData);
  }
  dialogConfirm() {
    this.$refs["validateForm"].validate((valid) => {
      if (valid) {
        if (this.formData.diagnosesNumber > 0) {
          this.$confirm(`添加次数后，将自动停用“无限次”且”无限期“的数据，是否确认添加？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.saveTongueTimes();
          })
        } else {
          this.saveTongueTimes();
        }
        
      } else {
        return false;
      }
    });
  }
  saveTongueTimes() {
    this.formData.diagnosesTypeName = this.getName(
          this.diagnosesTypeList,
          this.formData.diagnosesTypeId,
          "id",
          "dictValue"
        );
        if (this.formData.limitStatus == 1) {
          this.formData.expireTime = "";
        } else {
          this.formData.expireTime = this.formData.expireTime.replace("00:00:00", "23:59:59");
        }
        save(this.formData)
          .then((res) => {
            this.dialogClose();
            this.getData();
          })
          .catch((err) => {});
  }
  getTongueTimes(times) {
    if (times < 0) return "无限"
    return times;
  }

  useStatusChange($event) {
    if (!$event) this.useStatus = "全部";
    const diagnosesList = cloneDeep(this.deatilData.diagnosesList);
    if ($event == "全部" || !$event) {
      this.diagnosesList = diagnosesList;
      return;
    }
    if ($event == '使用中') {
      this.diagnosesList = diagnosesList?.filter((el) => {
        return el.expireStatusDesc == '使用中' || el.expireStatusDesc == '未使用';
      });
    } else {
      this.diagnosesList = diagnosesList?.filter((el) => {
        return el.expireStatusDesc == $event;
      });
    }
  }
  // this.formData.diagnosesTypeId == "10003"
  @Watch("formData.diagnosesTypeId", { immediate: true })
  setExpiredTime(value) {
    if (value == "10003") {
      Object.assign(this.formData, { limitStatus: 0, expireTime: "" })
      this.formData = cloneDeep(this.formData)
    }
  }
}


</script>

<style lang="scss" scoped src="./style.scss"></style>
